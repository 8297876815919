import React from 'react';
import { Link } from "react-router-dom";

function Home() {
  return <>
    <header>
      <div id="logo" onClick={() => {window.location.href = '/'}}>Šikulík</div>
    </header>

    <div id="tiles">
      <Link id="info" className="link" to='/info'>
        <span>Informatika</span>
      </Link>
      {/* <Link id="maths" className="link" to='/maths'>
        <span>Matematika</span>
      </Link> */}
      <Link id="pexeso" className="link" to='/pexeso'>
        <span>Pexeso</span>
      </Link>
      <Link id="match" className="link" to='/match'>
        <span>Priraď</span>
      </Link>
      <Link id="truefalse_teeth" className="link" to='/truefalse_teeth'>
        <span>Zdravé Zuby</span>
      </Link>
      {/* <Link id="xmas-ms" className="link" to='/xmas-ms'>
        <span>Vianočné úlohy MŠ</span>
      </Link>
      <Link id="xmas-zs" className="link" to='/xmas-zs'>
        <span>Vianočné úlohy ZŠ</span>
      </Link> */}
    </div>
  </>
}

export default Home;